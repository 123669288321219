.react-slideshow-container {
  align-items: center;
  display: flex;
  position: relative;
}

.react-slideshow-container .nav {
  z-index: 10;
  cursor: pointer;
  position: absolute;
}

.react-slideshow-container .nav:first-of-type {
  left: 0;
}

.react-slideshow-container .nav:last-of-type {
  right: 0;
}

.react-slideshow-container .default-nav {
  text-align: center;
  color: #fff;
  background: #fff9;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.react-slideshow-container .default-nav:hover, .react-slideshow-container .default-nav:focus {
  color: #666;
  background: #fff;
  outline: 0;
}

.react-slideshow-container .default-nav.disabled:hover {
  cursor: not-allowed;
}

.react-slideshow-container .default-nav:first-of-type {
  border-top: 0;
  border-right: 0;
  margin-right: -30px;
}

.react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
}

.react-slideshow-container + ul.indicators {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.react-slideshow-container + ul.indicators li {
  width: 7px;
  height: 7px;
  margin: 0;
  padding: 5px;
  display: inline-block;
  position: relative;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator {
  opacity: .25;
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  content: "";
  text-align: center;
  background: #000;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:hover, .react-slideshow-container + ul.indicators .each-slideshow-indicator.active {
  opacity: .75;
  outline: 0;
}

.react-slideshow-fadezoom-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap {
  flex-wrap: wrap;
  display: flex;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap > div {
  opacity: 0;
  position: relative;
}

.react-slideshow-wrapper .react-slideshow-fade-images-wrap > div[aria-hidden="true"] {
  display: none;
}

.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-wrapper .images-wrap.horizontal {
  flex-wrap: wrap;
  display: flex;
}

.react-slideshow-wrapper .images-wrap > div[aria-hidden="true"] {
  display: none;
}

body {
  margin: 0;
}
/*# sourceMappingURL=index.9cf08ef1.css.map */
